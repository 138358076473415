import { Box, Container, Heading, Text } from '@chakra-ui/layout';
import React from 'react';

import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from '@/app/used-cars/_components/image-gallery/ImageGallery';

import styles from './ImageGallery.module.scss';

export const Gallery = ({
  gallery,
  mainTitle,
}: {
  gallery: string[];
  mainTitle?: string;
}) => {
  return (
    <Box className={styles.galleryWrapper}>
      <Container maxW={'1280'} width={'100%'} px={{ base: '0', md: 4 }}>
        <Box
          className={styles.headingElement}
          marginBottom={{ base: 4, lg: 16 }}
        >
          <Heading as="h2" className={styles.titleLg}>
            Gallery
          </Heading>
        </Box>

        <ImageGallery gallery={gallery} />

        <Box
          paddingRight={{ base: '24px', lg: 0 }}
          paddingLeft={{ base: '24px', lg: '260px' }}
          mt={{ base: 4, lg: 12 }}
        >
          <Text>
            {`${new Date().getFullYear()}`} {mainTitle} shown in various trims
            with optional features. Not all colors, color combinations, and
            optional features can be equipped on all trims. Some colors and
            option selections may include, exclude, or require the purchase of
            additional options and packages. Limited inventory available.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
