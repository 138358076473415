import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Heading, Link, Text } from '@chakra-ui/react';
import slugify from 'react-slugify';

import Brochure from '@/app/_components/brochure/Brochure';
import { Gallery } from '@/app/_components/gallery/ImageGallery';
import { getDownloadLink } from '@/services/link.service';
import type { SpecialDetails } from '@/services/SpecialsService.service';
import { NewCarData } from '@/types/new-cars/new-cars.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';

import styles from './Variants.module.scss';

type Props = {
  specialDetails?: SpecialDetails;
  isSpecAvailable?: boolean;
  vehicleTitle?: string;
  newCarDetails?: NewCarData;
};

const Variants = ({
  specialDetails,
  isSpecAvailable,
  vehicleTitle,
  newCarDetails,
}: Props) => {
  const exteriorImages =
    newCarDetails?.gallery_exterior?.map((image) => image.image) ?? [];
  const interiorImages =
    newCarDetails?.gallery_interior?.map((image) => image.image) ?? [];
  const images = [...exteriorImages, ...interiorImages];

  const specialMmcode = specialDetails?.mmcode || '';

  const mmCodeSet = newCarDetails
    ? new Set(newCarDetails.specification_prices?.map((spec) => spec?.mm_code))
    : new Set();

  const hasSpecialMmcode = newCarDetails ? mmCodeSet.has(specialMmcode) : false;

  const otherMmcodes = newCarDetails
    ? [...mmCodeSet]
        .filter((code) => code && code !== specialMmcode)
        .slice(0, 1)
    : [];

  const combinedMmcodes = [specialMmcode, ...otherMmcodes]
    .filter(Boolean)
    .join(',');

  return (
    <>
      {images?.length > 0 ? (
        <Box className="element" key="gallery">
          <Gallery gallery={images} mainTitle={newCarDetails?.main_title} />
        </Box>
      ) : null}
      {newCarDetails?.brochure && (
        <Box
          width="100vw"
          marginLeft="calc(50% - 50vw)"
          paddingX={0}
          position="relative"
        >
          <Brochure
            model={newCarDetails?.main_title}
            brochure={newCarDetails?.brochure}
          />
        </Box>
      )}
      <Box
        paddingY={{ base: '10', md: '24', lg: '80px' }}
        paddingX={{ base: '6', md: '40', lg: '60px' }}
        className={styles.variantWrapper}
      >
        <Box
          className={styles.headingElement}
          marginBottom={{ base: '32px', md: '44px' }}
        >
          {specialDetails?.sub_title && (
            <Text fontSize="xs" marginBottom={2} textTransform="uppercase">
              {specialDetails.sub_title}
            </Text>
          )}
          {specialDetails?.title && (
            <Heading as="h2" className={styles.titleLg} marginBottom={2}>
              {specialDetails.title}
            </Heading>
          )}
          <Box className={styles.themeLinkWrapper} mt={8}>
            {vehicleTitle &&
              specialDetails?.vehicle_type === VehicleType.New && (
                <>
                  <Link
                    className={styles.themeLink}
                    href={`/new-cars/${slugify(vehicleTitle)}`}
                  >
                    View {vehicleTitle}
                  </Link>
                  <Link
                    className={styles.themeLink}
                    href={`/build-price/${slugify(vehicleTitle)}${specialMmcode ? `?mmcode=${specialMmcode}` : ''}`}
                  >
                    Build {vehicleTitle}
                  </Link>
                  {hasSpecialMmcode && (
                    <Link
                      className={styles.themeLink}
                      href={`/new-cars/${slugify(vehicleTitle)}/compare-models?mmcode=${combinedMmcodes}`}
                    >
                      Compare Models
                    </Link>
                  )}
                  {isSpecAvailable && (
                    <Link
                      className={styles.themeLink}
                      href={`/new-cars/${slugify(vehicleTitle)}/specs/${specialMmcode}`}
                    >
                      View Specs
                    </Link>
                  )}
                  {newCarDetails?.brochure && (
                    <Link
                      role="link"
                      className={styles.themeLink}
                      href={getDownloadLink(newCarDetails.brochure)}
                      target="_blank"
                    >
                      Brochure
                      <DownloadIcon className={styles.menuIcon} />
                    </Link>
                  )}
                </>
              )}
          </Box>
        </Box>
        {specialDetails?.description && (
          <Box
            dangerouslySetInnerHTML={{ __html: specialDetails.description }}
            className={styles.variantTypeContainer}
          />
        )}
      </Box>
    </>
  );
};

export default Variants;
