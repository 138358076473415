'use client';

import { Container } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { Suspense, useEffect } from 'react';
import React from 'react';
import slugify from 'react-slugify';

import Promotions from '@/app/_components/promotions/Promotions';
import { useFloatingCTA, useSetFormItem } from '@/hooks/floating-cta.hook';
import { getLeadTypeForSpecial } from '@/services/specials/specials.service';
import { SpecialDetails } from '@/services/SpecialsService.service';
import type { FeaturedItem } from '@/types/home/home.types';
import { NewCarData } from '@/types/new-cars/new-cars.types';
import { Range } from '@/types/request/request.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';

import BannerSection from './BannerSection';
import CTA from './CTA';
import Overview from './Overview';
import Variants from './Variants';

interface Props {
  promotionsItems: FeaturedItem[];
  specialDetails: SpecialDetails;
  ranges?: Range[];
  isSpecAvailable?: boolean;
  vehicleTitle?: string;
  newCarDetails?: NewCarData;
}

const PromotionsDetails = ({
  promotionsItems,
  specialDetails,
  ranges = [],
  vehicleTitle,
  isSpecAvailable,
  newCarDetails,
}: Props) => {
  const setFormItem = useSetFormItem();
  const { setSecondaryFloatingButton } = useFloatingCTA();

  useEffect(() => {
    const leadType = getLeadTypeForSpecial(specialDetails);

    setFormItem({
      type: 'special',
      special: specialDetails,
      leadType: leadType,
    });

    if (specialDetails?.vehicle_type === VehicleType.New && vehicleTitle) {
      setSecondaryFloatingButton({
        text: `View ${newCarDetails?.main_title}`,
        url: '/new-cars' + '/' + slugify(vehicleTitle),
        display: 'all',
        colors: {
          desktop: {
            background: '#f3c32c',
            text: '#ffffff',
          },
        },
      });
    }

    return () => {
      setFormItem(undefined);
      setSecondaryFloatingButton(null);
    };
  }, [specialDetails, setFormItem, setSecondaryFloatingButton]);

  return (
    <div>
      {specialDetails ? (
        <>
          <BannerSection
            specialDetails={specialDetails}
            imageKeys={{
              desktopKey: 'image-1440-680',
              mobileKey: 'image-430-305',
            }}
          />
          <Suspense fallback={null}>
            <Container maxW={'1280'} width={'100%'}>
              {specialDetails && specialDetails?.content_blocks?.length && (
                <Overview specialDetails={specialDetails} />
              )}
              <Variants
                isSpecAvailable={isSpecAvailable}
                vehicleTitle={vehicleTitle}
                specialDetails={specialDetails}
                newCarDetails={newCarDetails}
              />
              {specialDetails.call_to_actions &&
              specialDetails?.call_to_actions?.length > 0 ? (
                <Box
                  width="100vw"
                  marginLeft="calc(50% - 50vw)"
                  paddingX={0}
                  position="relative"
                >
                  <CTA call_to_actions={specialDetails.call_to_actions} />
                </Box>
              ) : null}
            </Container>
          </Suspense>
        </>
      ) : (
        <Container maxW={'1280'} width={'100%'}>
          <Box>Promotion not found</Box>
        </Container>
      )}

      <Suspense fallback={null}>
        <Promotions
          isShowNavigation
          featuredItems={promotionsItems as any[]}
          sectionTitle="Recommended Promotions"
          ranges={ranges}
        />
      </Suspense>
    </div>
  );
};

export default PromotionsDetails;
